<template>
  <div class="main-content">
    <div v-if="isLoading" class="loading_page spinner spinner-primary mr-3"></div>
    <b-card class="wrapper" v-if="!isLoading">
      <vue-good-table
        mode="remote"
        :columns="columns"
        :totalRows="totalRows"
        :rows="holidays"
        @on-page-change="onPageChange"
        @on-per-page-change="onPerPageChange"
        @on-sort-change="onSortChange"
        @on-search="onSearch"
        :search-options="{
        enabled: false,
        placeholder: $t('Search this table'),  
      }"
        :select-options="{ 
          enabled: false ,
          clearSelectionText: '',
        }"
        @on-selected-rows-change="selectionChanged"
        :pagination-options="{
        enabled: true,
        mode: 'records',
        nextLabel: 'next',
        prevLabel: 'prev',
      }"
        styleClass="vgt-table bordered striped table-hover"
      >
        <div slot="selected-row-actions">
          <button class="btn btn-danger btn-sm" @click="delete_by_selected()">{{$t('Del')}}</button>
        </div>
        <div slot="table-actions">
          <b-button
            @click="New_Holiday()"
            class="btn-rounded"
            variant="btn btn-primary btn-icon mr-1"
          >
            {{$t('Add')}}
          </b-button>
        </div>

        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'actions'">
            <a @click="Edit_Holiday(props.row)" class="cursor-pointer" title="Edit" v-b-tooltip.hover>
              <img :src="$helpers.getIcons('edit')" alt="" class="cursor-pointer action-icon-grid">
            </a>
            <a title="Delete" v-b-tooltip.hover class="cursor-pointer" @click="Remove_Holiday(props.row.id)">
              <img :src="$helpers.getIcons('delete')" alt="" class="cursor-pointer action-icon-grid">
            </a>
          </span>
        </template>
      </vue-good-table>
    </b-card>

    <validation-observer ref="Create_Holiday">
      <b-modal hide-footer size="md" id="New_Modal_Holiday" :title="editmode?$t('Edit'):$t('Add')">
        <b-form @submit.prevent="Submit_Holiday">
          <b-row>
              <!-- title -->
              <b-col md="12">
                <validation-provider
                  name="title"
                  :rules="{ required: true}"
                  v-slot="validationContext"
                >
                  <b-form-group :label="$t('title') + ' ' + '*'">
                    <b-form-input
                      :placeholder="$t('Enter title')"
                      :state="getValidationState(validationContext)"
                      aria-describedby="title-feedback"
                      label="title"
                      v-model="holiday.title"
                    ></b-form-input>
                    <b-form-invalid-feedback id="title-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- start date -->
              <b-col md="12">
                <validation-provider
                  name="start_date"
                  :rules="{ required: true}"
                  v-slot="validationContext"
                >
                    <b-form-group :label="$t('Start Date') + ' ' + '*'">
                        <Datepicker id="start_date" name="start_date" :placeholder="$t('Enter Start date')" v-model="holiday.start_date" 
                            input-class="form-control back_important" format="yyyy-MM-dd"  @closed="holiday.start_date=formatDate(holiday.start_date)">
                        </Datepicker>
                        <b-form-invalid-feedback id="start_date-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                     </b-form-group>
                </validation-provider>
              </b-col>

               <!-- end date -->
              <b-col md="12">
                <validation-provider
                  name="Finish_Date"
                  :rules="{ required: true}"
                  v-slot="validationContext"
                >
                    <b-form-group :label="$t('Finish Date') + ' ' + '*'">
                        <Datepicker id="end_date" name="end_date" :placeholder="$t('Enter Finish date')" v-model="holiday.end_date" 
                            input-class="form-control back_important" format="yyyy-MM-dd"  @closed="holiday.end_date=formatDate(holiday.end_date)">
                        </Datepicker>
                        <b-form-invalid-feedback id="end_date-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                     </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Please_provide_any_details -->
            <b-col md="12">
                <b-form-group :label="$t('Please provide any details')">
                  <b-form-textarea
                    rows="3"
                    :placeholder="$t('Please provide any details')"
                    label="description"
                    v-model="holiday.description"
                  ></b-form-textarea>
                </b-form-group>
            </b-col>


            <b-col md="12" class="mt-3">
                <b-button variant="primary" type="submit"  :disabled="SubmitProcessing"><i class="i-Yes me-2 font-weight-bold"></i> {{$t('submit')}}</b-button>
                  <div v-once class="typo__p" v-if="SubmitProcessing">
                    <div class="spinner sm spinner-primary mt-3"></div>
                  </div>
            </b-col>

          </b-row>
        </b-form>
      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import { VueGoodTable } from 'vue-good-table'
import Vue from 'vue'
import axios from 'axios'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
const Fire = new Vue();
const apiUrl = `${jwtDefaultConfig.apiEndpoint}/hrm`;
import { hideLoader, showErrorNotification, showLoader, showSuccessNotification} from '@/@core/comp-functions/ui/app'

export default {
  metaInfo: {
    title: "Holiday"
  },
   components: {
    Datepicker,
    VueGoodTable
  },
  data() {
    return {
      isLoading: true,
      SubmitProcessing:false,
      serverParams: {
        columnFilters: {},
        sort: {
          field: "id",
          type: "desc"
        },
        page: 1,
        perPage: 10
      },
      data: new FormData(),
      selectedIds: [],
      totalRows: "",
      search: "",
      limit: "10",
      editmode: false,
      companies:[],
      holidays: {}, 
      holiday: {
          title: "",
          start_date:"",
          end_date:"",
          description:"",
      }, 
    };
  },

  computed: {
    columns() {
      return [
        {
          label: this.$t("Holiday"),
          field: "title",
          tdClass: "text-center",
          thClass: "text-center"
        },
        {
          label: this.$t("Start Date"),
          field: "start_date",
          tdClass: "text-center",
          thClass: "text-center"
        },
        {
          label: this.$t("Finish Date"),
          field: "end_date",
          tdClass: "text-center",
          thClass: "text-center"
        },
        {
          label: this.$t("Action"),
          field: "actions",
          html: true,
          tdClass: "text-center",
          thClass: "text-center",
          sortable: false
        }
      ];
    }
  },

  methods: {
    //---- update Params Table
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    //---- Event Page Change
    onPageChange({ currentPage }) {
      if (this.serverParams.page !== currentPage) {
        this.updateParams({ page: currentPage });
        this.Get_Holidays(currentPage);
      }
    },

    //---- Event Per Page Change
    onPerPageChange({ currentPerPage }) {
      if (this.limit !== currentPerPage) {
        this.limit = currentPerPage;
        this.updateParams({ page: 1, perPage: currentPerPage });
        this.Get_Holidays(1);
      }
    },

    //---- Event Select Rows
    selectionChanged({ selectedRows }) {
      this.selectedIds = [];
      selectedRows.forEach((row, index) => {
        this.selectedIds.push(row.id);
      });
    },

    //---- Event Sort Change

    onSortChange(params) {
      let field = params[0].field;
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field
        }
      });
      this.Get_Holidays(this.serverParams.page);
    },

    //---- Event Search
    onSearch(value) {
      this.search = value.searchTerm;
      this.Get_Holidays(this.serverParams.page);
    },

    //---- Validation State Form
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },

    formatDate(d){
        var m1 = d.getMonth()+1;
        var m2 = m1 < 10 ? '0' + m1 : m1;
        var d1 = d.getDate();
        var d2 = d1 < 10 ? '0' + d1 : d1;
        return [d.getFullYear(), m2, d2].join('-');
    },
  

    //------------- Submit Validation Create & Edit Holiday
    Submit_Holiday() {
      this.$refs.Create_Holiday.validate().then(success => {
        if (!success) {
          showErrorNotification(this, 'Please fill the form correctly');
        } else {
          if (!this.editmode) {
            this.Create_Holiday();
          } else {
            this.Update_Holiday();
          }
        }
      });
    },

   //------------------------------ Show Modal (Create Holiday) -------------------------------\\
    New_Holiday() {
        this.reset_Form();
        this.editmode = false;
        this.Get_Data_Create();
        this.$bvModal.show("New_Modal_Holiday");
    },

    //------------------------------ Show Modal (Update Holiday) -------------------------------\\
    Edit_Holiday(holiday) {
        this.editmode = true;
        this.reset_Form();
        this.Get_Data_Edit(holiday.id);
        this.holiday = holiday;
        this.$bvModal.show("New_Modal_Holiday");
    },

     //---------------------- Get_Data_Create  ------------------------------\\
      Get_Data_Create() {
          axios
              .get(apiUrl+"/holiday/create")
              .then(response => {
                  this.companies   = response.data.companies;
              })
              .catch(error => {
                  
              });
      },

      //---------------------- Get_Data_Edit  ------------------------------\\
      Get_Data_Edit(id) {
        axios
             .get(`${apiUrl}/holiday/${id}/edit`)
            .then(response => {
                this.companies   = response.data.companies;
            })
            .catch(error => {
                
            });
    },

       Selected_Company(value) {
          if (value === null) {
          }
      },



    //--------------------------Get ALL holidays ---------------------------\\

    Get_Holidays(page) {
      // Start the progress bar.
      axios
        .get(
          apiUrl+"/holiday?page=" +
            page +
            "&SortField=" +
            this.serverParams.sort.field +
            "&SortType=" +
            this.serverParams.sort.type +
            "&search=" +
            this.search +
            "&limit=" +
            this.limit
        )
        .then(response => {
          this.totalRows = response.data.totalRows;
          this.holidays = response.data.holidays;

          // Complete the animation of theprogress bar.
          this.isLoading = false;
        })
        .catch(response => {
          // Complete the animation of theprogress bar.
          setTimeout(() => {
            this.isLoading = false;
          }, 500);
        });
    },

    //------------------------------- Create holiday ------------------------\\
    Create_Holiday() {
      
        var self = this;
        self.SubmitProcessing = true;
        axios.post(apiUrl+"/holiday", {
            title: self.holiday.title,
            start_date: self.holiday.start_date,
            end_date: self.holiday.end_date,
            description: self.holiday.description,
        }).then(response => {
            this.SubmitProcessing = false;
            Fire.$emit("Event_Holiday");
            showSuccessNotification(this, 'Created successfully!');
        })
        .catch(error => {
          this.SubmitProcessing = false;
          showErrorNotification(this, "Invalid Data")
        });
    },

    //------------------------------- Update holiday ------------------------\\
    Update_Holiday() {

      var self = this;
      self.SubmitProcessing = true;
      axios.put(apiUrl+"/holiday/" + self.holiday.id, {
          title: self.holiday.title,
          start_date: self.holiday.start_date,
          end_date: self.holiday.end_date,
          description: self.holiday.description,
      }).then(response => {
            this.SubmitProcessing = false;
            Fire.$emit("Event_Holiday");
            showSuccessNotification(this, 'Updated successfully!');
        })
        .catch(error => {
          this.SubmitProcessing = false;
          showErrorNotification(this, "Invalid Data")
        });
    },

    //------------------------------- reset Form ------------------------\\
    reset_Form() {
     this.holiday = {
        id: "",
        title: "",
        start_date:"",
        end_date:"",
        description:"",
    };
    },

    //------------------------------- Delete holiday ------------------------\\
    Remove_Holiday(id) {
      this.$swal({
        text: '',
        title: this.$t('Please confirm you want to delete this item?'),
        iconHtml: this.$helpers.swalIcon('alert.png'),
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('No'),
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
          icon: 'border-0',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios
            .delete(apiUrl+"/holiday/" + id)
            .then(() => {
              showSuccessNotification(this, 'Deleted successfully!');

              Fire.$emit("Delete_Holiday");
            })
            .catch(() => {
              showErrorNotification(this, "Something went wrong while deleting item!");
            });
        }
      });
    },

    //---- Delete department by selection

    delete_by_selected() {
      this.$swal({
        text: '',
        title: this.$t('Please confirm you want to delete this item?'),
        iconHtml: this.$helpers.swalIcon('alert.png'),
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('No'),
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
          icon: 'border-0',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          // Start the progress bar.
          axios
            .post(apiUrl+"/holiday/delete/by_selection", {
              selectedIds: this.selectedIds
            })
            .then(() => {
              showSuccessNotification(this, 'Deleted successfully!');

              Fire.$emit("Delete_Holiday");
            })
            .catch(() => {
              // Complete the animation of theprogress bar.
              showErrorNotification(this, "Something went wrong while deleting item!");
            });
        }
      });
    }
  },

  //----------------------------- Created function-------------------\\

  created: function() {
    this.Get_Holidays(1);

    Fire.$on("Event_Holiday", () => {
      setTimeout(() => {
        this.Get_Holidays(this.serverParams.page);
        this.$bvModal.hide("New_Modal_Holiday");
      }, 500);
    });

    Fire.$on("Delete_Holiday", () => {
      setTimeout(() => {
        this.Get_Holidays(this.serverParams.page);
      }, 500);
    });
  }
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
